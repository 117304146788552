const parts = [
  {name: 'Roll-a-thor', number: 1},
  {name: 'Peanut crusher', number: 2},
  {name: 'D.A.V.E', number: 3},
  {name: 'Andy Roid', number: 4},
  {name: 'Spanner mate', number: 5},
  {name: 'Drillbit 2000', number: 6},
]

export default parts
